import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../context/HomeBlogContext'
import { api_url } from '../../../constants/base_url';
import Loading from '../../../components/shared/Loading/Loading';
import { IoMdAddCircle } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa';
import FooterSection from './Sections/FooterSection';

const DashboardFooterContent = () => {
  const {langs, lang, setLang, blog,addLang, deleteLang, allLanguages, isLangsLoading, isAddLangLoading, isDeleteLangLoading, isBlogLoading} = useHomeBlog()
  const [language, setLanguage] = useState("")
  useEffect(() => {
    if(allLanguages){
      setLanguage(allLanguages?.filter(lang => !langs.some(l => l.name === lang))[0])
    }
  },[allLanguages])
  return (
    <div style={{padding:"20px"}}>
      <h1>Footer Content</h1>
      {isLangsLoading?<Loading color={"#0791BE"}/>:<div style={{display:"flex", alignItems:"center",justifyContent:"space-between", gap:"10px", width:"100%", marginBottom:"20px"}}>
      <div style={{display:"flex",alignItems:"center", justifyContent:"center", gap:"15px", height:"40px", margin:"10px 0"}}>
      <select onChange={(e) => {
        setLang(JSON.parse(e.target.value))
      }}>
        {langs.map(lang => (
          <option key={lang.id} value={JSON.stringify(lang)}>{lang.name}</option>
        ))}
      </select>
      <button disabled={isDeleteLangLoading} onClick={() => {
          deleteLang(lang.id)
        }} style={{width:"30px", height:"30px", border:"none", outline:"none", fontSize:"0.8rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"6px"}}>
          {<FaTrashAlt />}
        </button>
      </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"15px", height:"40px", margin:"10px 0"}}>
        <select onChange={(e) => {
            setLanguage(e.target.value)
          }}>
            {allLanguages?.filter(lang => !langs.some(l => l.name === lang)).map(lang => (
            <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
            <button disabled={!language || isAddLangLoading} style={{width:"40px",height:"40px", border:"none", outline:"none", fontSize:"1.6rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"12px"}} onClick={() => {
                addLang({language:language, description:language, content:language, title:language})
            }}>{isAddLangLoading?<Loading color={"white"}/>:<IoMdAddCircle />}</button>
        </div>
      </div>}
      {(isBlogLoading || isDeleteLangLoading)?<Loading color={"#0791BE"}/>:<>
            {!blog?.sub_card_12 && <p style={{textAlign:"center", fontSize:"32px", fontWeight:700, color:"#D51C29"}}>Please finish adding home and about content first</p>}
            {blog?.sub_card_12 && <FooterSection/>}

      </>}
      </div>
  )
}

export default DashboardFooterContent