import React, { useEffect, useState } from 'react'
import img8 from "../../../../images/img8.jpg";
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import icon5 from "../../../../images/icon5.png";
import { api_url } from '../../../../constants/base_url';
import Loading from '../../../../components/shared/Loading/Loading';


const CallbackSection = () => {
  const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
  const [content, setContent] = useState([])
  const [title, setTitle] = useState("")

  const urlToFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.split("/").pop();
    return new File([blob], filename, { type: blob.type });
  };
  useEffect(() => {
    if(blog?.sub_card_4){
      setContent(blog?.sub_card_4?.content?.split("-.-"))
      setTitle(blog?.sub_card_4?.title)
    }else{
      setContent([])
      setTitle("")
    }
  },[blog, lang])
  const updateCallbackSection = () => {
    if(blog){
      updateSection({
        blogLanguageId: lang.id,
        index: 4,
        content: content.join("-.-"),
        title: title,
        _method: "PUT"
      })
    }
  }

  const changeCallbackImage = async (img) => {
    const formData = new FormData();
    formData.append("blogLanguageId", lang.id);
    formData.append("index", 4);
    formData.append("_method", "PUT");
    formData.append(`images[0]`, img);
    await updateSection(formData)
  }


  const addCallbackSections = async () => {
    const formData = new FormData();
    formData.append('blogLanguageId', lang.id);
    formData.append('title', "Callback for more");
    formData.append(`images[0]`, await urlToFile(img8));
    formData.append('content', [
      "GO TRAVEL. DISCOVER. REMEMBER US!!",
      "We are committed to curate and flawlessly execute travel experiences that allow travelers to explore the world with ease and create memories that last a lifetime",
      "500K+",
      "Satisfied Clients",
      "250K+",
      "Satisfied Clients",
      "15K+",
      "Satisfied Clients",
      "10K+",
      "Satisfied Clients",
      "Our 24/7 Emergency Phone Services",
      "Call",
      "+201033973047"
    ].join("-.-"));

    addSection(formData);
  }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
            {blog?.sub_card_4?<>
              <div className="row no-gutters align-items-center">
                <div className="col-12">
                  
                  <div className="callback-inner" style={{backgroundColor:"#0791BE", marginBottom:"40px", paddingLeft:"160px"}}>
                    <div className="section-heading section-heading-white">
                  
                      <input className="dash-style" style={{backgroundColor:"transparent", border:"none", outline:"none", textTransform:"uppercase", fontSize:"18px", width:"100%"}} value={title} onChange={(e) => {
                        setTitle(e.target.value)
                      }}/>
                      <input type='text' 
                        style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"48px", fontWeight:"900", display:"block", width:"100%"}} 
                        value={content[0]}
                        onChange={(e) => {
                          const newContent = [...content]
                          newContent[0] = e.target.value
                          setContent(newContent)
                        }}
                        />
                      <textarea style={{backgroundColor:"transparent", border:"none", outline:"none", fontSize:"16px", color:"white"}}
                        value={content[1]}
                        onChange={(e) => {
                          const newContent = [...content]
                          newContent[1] = e.target.value
                          setContent(newContent)
                        }}
                        />
                    </div>
                    <div className="row">
                      <div className="col-6">

                    <div className="callback-counter-wrap">
                      {Array(4).fill(0).map((_,i) => <div>
                        <input type='text' value={content[i*2+2]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"48px", fontWeight:"900", display:"block", width:"100%"}} onChange={(e) => {
                          const newContent = [...content]
                          newContent[i*2+2] = e.target.value
                          setContent(newContent)
                        }}/>
                        <input type='text' value={content[i*2+3]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"16px", display:"block", width:"100%"}} onChange={(e) => {
                          const newContent = [...content]
                          newContent[i*2+3] = e.target.value
                          setContent(newContent)
                        }}/>
                      </div>)}
                    </div>
                      </div>
                      <div className="col-6">

                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`callback-fileInput`}
                      onChange={async (e)=>{
                      const file = e.target.files[0];
                      if (file) {
                          // const image = await urlToFile(file);
                          changeCallbackImage(file); // Pass the file directly
                      }
                      }}
                  />
                  
                  {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<div
                    className="callback-img"
                    style={{ cursor: "pointer",backgroundImage: `url(${api_url}${blog?.sub_card_4?.image?.[0]})`.replace("/api/", "/storage/"), width:"400px", height:"400px", backgroundSize:"cover", backgroundPosition:"center", backgroundRepeat:"no-repeat" }}
                    onClick={() => {
                      document.getElementById(`callback-fileInput`).click()
                    }}
                  ></div>}
                      </div>
                    </div>
                    <div className="support-area">
                  <div className="support-icon">
                    <img src={icon5} alt="" />
                  </div>
                  <div className="support-content" style={{minWidth:"500px"}}>
                    <input type='text' value={content[10]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#101f46", fontSize:"16px", fontWeight:"700", display:"block", width:"100%"}}
                      onChange={(e) => {
                        const newContent = [...content]
                        newContent[10] = e.target.value
                        setContent(newContent)
                      }}
                    />
                        <input type='text' value={content[11]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#d51c29", fontSize:"32px", fontWeight:"900", display:"block", width:"100%"}} 
                          onChange={(e) => {
                            const newContent = [...content]
                            newContent[11] = e.target.value
                            setContent(newContent)
                          }}
                        />
                        <input type='text' value={content[12]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#d51c29", fontSize:"32px", fontWeight:"900", display:"block", width:"100%"}} 
                          onChange={(e) => {
                            const newContent = [...content]
                            newContent[12] = e.target.value
                            setContent(newContent)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-callback-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateCallbackSection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-callback-section"
                  value="Add Callback Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addCallbackSections}
                />}
            
      </div>
  )
}

export default CallbackSection