import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';

import icon15 from "../../../../images/icon15.png";
import icon16 from "../../../../images/icon16.png";
import icon17 from "../../../../images/icon17.png";
import Loading from '../../../../components/shared/Loading/Loading';
import SectionHeading from '../../../../components/AboutComponents/SectionHeading';
import AboutService from '../../../../components/AboutComponents/AboutService';

const WhyUsSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")
    const imgs = [icon15, icon16, icon17]
    useEffect(() => {
        if(blog?.sub_card_11){
          setContent(blog?.sub_card_11?.content?.split("-.-"))
          setTitle(blog?.sub_card_11?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])

      const updateWhyUsSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 11,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }
      const addWhyUsSections = () => {
        addSection({
          blogLanguageId: lang.id,
          title: "Why Album Travel",
          content: ["We are committed to curate and flawlessly execute travel experiences.",
            "Established in 2006, Album Travel started its journey with just one goal: to offer remarkable travel experiences that help people create unforgettable memories that will last forever. Since then, Album Travel has made it its mission to bring curious travelers from all over the world to experience,",
            "connect and explore the beauty of the Middle East. Initially established to cater to the travelers in the Far East region, we have successfully expanded our travel packages to encompass the global market. Our exceptional attention to detail, unrivaled expertise, and unwavering commitment to customer satisfaction make us the preferred choice for those seeking extraordinary journeys.",
            "AFFORDABLE PRICE",
            "Discover Egypt with our budget-friendly travel packages, offering modern transport, expert guides, and 24/7 support.",
            "BEST DESTINATION",
            "Discover the beauty of Egypt with our customizable travel packages. Enjoy top-notch services and unforgettable experiences, all within your budget.",
            "PERSONAL SERVICE",
            "Enjoy personalized travel experiences with expert guides and 24/7 support.",
          ].join("-.-"),
        })
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_11?<>
            <section className="about-section about-page-section">
        <div className="about-service-wrap">
          <div className="container">
                <SectionHeading
                heading={title}
                subheading={content[0]}
                description1={content[1]}
                description2={content[2]}
                contentEditable
                setTitle={setTitle}
                setContent={setContent}
                content={content}
                />
                <div className="about-service-container">
                <div className="row">
                    {Array(3).fill(null).map((service, index) => (
                    <div className="col-lg-4" key={index}>
                        <AboutService contentEditable index={index*2 + 3} setContent={setContent} content={content} icon={imgs[index]} title={content[index*2 + 3]} description={content[index*2 + 4]} />
                    </div>
                    ))}
                </div>
                </div>
            </div>
            </div>
            </section>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-why-us-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateWhyUsSection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-why-us-section"
                  value="Add Why Us Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addWhyUsSections}
                />}
    </div>
  )
}

export default WhyUsSection