import AdventureAndActivity from "../components/adventureAndActivity";
import SectionHeading from "../components/sectionheading";
import HomeSliderSection from "../components/slider/heroslider";
import SpecialTravelOffer from "../components/specialTravelOffer";
import TopNotchDeals from "../components/topNotchDeals";

import img7 from "../images/img7.jpg";
import img8 from "../images/img8.jpg";
import img9 from "../images/img9.jpg";
import img12 from "../images/img12.jpg";
import img13 from "../images/img13.jpg";
import img14 from "../images/img14.jpg";
import img15 from "../images/img15.jpg";
import camelTrip from "../images/camel-trip.jpg";

import img24 from "../images/img24.jpg";

import icon12 from "../images/icon12.png";
import icon13 from "../images/icon13.png";
import icon14 from "../images/icon14.png";
import SubscribeSection from "../components/subscribeSection";
import WhatsAppButton from "../components/shared/WhatsAppButton";
import TestimonialSection from "../components/TestimonialSection";
import ClientSection from "../components/ClientSection";
import CallbackSection from "../components/CallbackInnerSection";
import PackageCard from "../components/packages/packagecard";
import CustomButton from "../components/shared/customButton";
import { ADDRESS, INFO_MAILE, PHONE1, PHONE2 } from "../globals";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { api_url } from "../constants/base_url";
import axios from "axios";
import { useHomeBlog } from "../context/HomeBlogContext";

const Home = () => {
  const { data } = useQuery({
    queryKey: ["tours"], // same key
    queryFn: () =>
      axios.get(`${api_url}tour`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });
  const {blog, isBlogLoading} = useHomeBlog()

  return (
    <>
      <main id="content" class="site-main">
        <HomeSliderSection />
        <section class="package-section recommended">
          <div class="container-fluid">
            <SectionHeading
              title={blog?.sub_card_2?.title || "EXPLORE GREAT PLACES"}
              headingTextAlignment="text-center"
              containerTextsStyle="col-lg-8 offset-lg-2 mt-5"
            >
              <h4>{blog?.sub_card_2?.content?.split("-.-")[0] || "Your recently viewed and recommended trips"}</h4>
            </SectionHeading>
            <div class="package-inner">
              <div class="row px-lg-5">
                {data?.data?.data?.data.map((item) => (
                  <PackageCard
                    recommended={true}
                    people={item.number_of_people}
                    location={item.location}
                    rating={item.rating}
                    reviews={item.number_of_reviews}
                    duration={item.duration_days}
                    image={item.image}
                    title={item.title}
                    description={item.description}
                    id={item?.id}
                  >
                    {/* <span>{item.price} </span> / per person */}
                    {/* {item?.HasOffer&&(
                      <div className="package-offer">Discount %</div>
                    )} */}
                  </PackageCard>
                ))}
              </div>
              <CustomButton href={"/tours"}>{blog?.sub_card_2?.content?.split("-.-")[2] || "VIEW ALL PACKAGES"}</CustomButton>
            </div>
          </div>
        </section>

        <section class="destination-section home">
          <div class="container-fluid px-lg-5">
            <TopNotchDeals />
          </div>
        </section>

        <section class="package-section recommended">
          <div class="container-fluid ">
            <SectionHeading
              title={blog?.sub_card_2?.title || "EXPLORE GREAT PLACES"}
              headingTextAlignment="text-center"
              containerTextsStyle="col-lg-8 offset-lg-2 mt-5"
            >
              <h4>{blog?.sub_card_2?.content?.split("-.-")[1] || "Book now and save up to 15% with our Last Minute Deals"}</h4>
            </SectionHeading>
            <div class="package-inner">
              <div class="row px-lg-5">
                {data?.data?.data?.data.map((item) => (
                  <PackageCard
                    recommended={true}
                    people={item.number_of_people}
                    location={item.location}
                    rating={item.rating}
                    reviews={item.number_of_reviews}
                    duration={item.duration_days}
                    image={item.image}
                    title={item.title}
                    description={item.description}
                    id={item?.id}
                  >
                    {/* <del> {item.oldPrice}</del>
                    <span>
                      {" "}
                      <ins>{item.price}</ins>{" "}
                    </span> */}
                    {/* {item?.HasOffer &&(
                      <span className="text-danger"> {item.offer}% OFF</span>
                    )} */}
                  </PackageCard>
                ))}
              </div>
              <CustomButton href={"/tours"}>{blog?.sub_card_2?.content?.split("-.-")[2] || "VIEW ALL PACKAGES"}</CustomButton>
            </div>
          </div>
        </section>
        <CallbackSection />

        <section class="activity-section">
          <div class="container-fluid">
            <AdventureAndActivity />
          </div>
        </section>

        <section class="special-section">
          <div class="container">
            <SpecialTravelOffer />
          </div>
        </section>

        <section class="best-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-5">
                
                <SectionHeading title={blog?.sub_card_7?.title || "OUR TOUR GALLERY"}>
                  <h2>{blog?.sub_card_7?.content?.split("-.-")?.[0] ||"BEST TRAVELER'S SHARED PHOTOS"}</h2>
                  <p>
                    {blog?.sub_card_7?.content?.split("-.-")?.[1] ||`Enjoy personalized service with our dedicated team, ensuring
                    your travel experience is seamless and memorable. Our
                    professional guides and 24/7 support are committed to
                    providing exceptional, customized service for
                    every traveler.`}
                  </p>
                </SectionHeading>

                <figure class="gallery-img">
                  <img src={blog?.sub_card_7?.image[0]?`${api_url}${blog?.sub_card_7?.image[0]}`.replace("/api/","/storage/"):img12} alt="" />
                </figure>
              </div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-sm-6">
                    <figure class="gallery-img">
                      <img src={blog?.sub_card_7?.image[1]?`${api_url}${blog?.sub_card_7?.image[1]}`.replace("/api/","/storage/"):img13} alt="" />
                    </figure>
                  </div>
                  <div class="col-sm-6">
                    <figure class="gallery-img">
                      <img src={blog?.sub_card_7?.image[2]?`${api_url}${blog?.sub_card_7?.image[2]}`.replace("/api/","/storage/"):img14} alt="" />
                    </figure>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <figure class="gallery-img">
                      <img src={blog?.sub_card_7?.image[3]?`${api_url}${blog?.sub_card_7?.image[3]}`.replace("/api/","/storage/"):img15} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ClientSection />

        <SubscribeSection />
        <TestimonialSection />
        <section class="contact-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div
                  class="contact-img"
                  style={{ backgroundImage: blog?.sub_card_10?.image?.[0]?`url(${api_url}${blog?.sub_card_10?.image?.[0]})`.replace("/api/","/storage/"):`url(${img24})` }}
                ></div>
              </div>
              <div class="col-lg-8">
                <div class="contact-details-wrap">
                  <div class="row">
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon12} alt="" />
                        </div>
                        <ul>
                          <li>
                            <a href="#">{blog?.sub_card_10?.content?.split("-.-")?.[0]||INFO_MAILE}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon13} alt="" />
                        </div>
                        <ul>
                          {blog?.sub_card_10?.content?.split("-.-")?.slice(3).map((item, index) => (<li>
                            <a href="#">{item}</a>
                          </li>))}
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon14} alt="" />
                        </div>
                        <ul>
                          <li>{blog?.sub_card_10?.content?.split("-.-")?.[1]||ADDRESS}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-btn-wrap">
                  <h3>{blog?.sub_card_10?.title || "LET'S JOIN US FOR MORE UPDATE !!"}</h3>
                  <Link to="/contact" class="button-primary">
                    {blog?.sub_card_10?.content?.split("-.-")?.[2]||"LEARN MORE"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <WhatsAppButton />
    </>
  );
};

export default Home;
