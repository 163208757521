import SectionHeading from "./sectionheading";
import DestinationRow from './destinations/destinationRow';
import { useHomeBlog } from "../context/HomeBlogContext";

const TopNotchDeals = () => {
    const {blog, isBlogLoading} = useHomeBlog()
    
    return (
        <>
            <SectionHeading title={blog?.sub_card_3?.title || "POPULAR DESTINATION"} rowAlignment="align-items-end" containerTextsStyle="col-lg-7">
                <h2>{blog?.sub_card_3?.content?.split("-.-")[0] ||"TOP NOTCH Deals"}</h2>
            </SectionHeading>

            <DestinationRow btnText={blog?.sub_card_3?.content?.split("-.-")[1]}/>
        </>
    )
}

export default TopNotchDeals