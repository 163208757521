import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import ActivitySectionHeading from '../../../../components/ActivityComponents/SectionHeading';
import Loading from '../../../../components/shared/Loading/Loading';

const OurAssociatesSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")

    useEffect(() => {
        if(blog?.sub_card_12){
          setContent(blog?.sub_card_12?.content?.split("-.-"))
          setTitle(blog?.sub_card_12?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])
      const updateOurAssociatesSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 12,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }
      const addOurAssociatesSections = () => {
        addSection({
          blogLanguageId: lang.id,
          title: "OUR ASSOCIATES",
          content: ["PARTNER'S AND CLIENTS",
            "To become a leader and trusted partner in the tourism industry in Egypt and the Middle East.",
          ].join("-.-"),
        })
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_12?<>
            <section className="activity-section">
                <div className="container">
                    <ActivitySectionHeading allContent={content} setTitle={setTitle} setContent={setContent} contentEditable title={title} subtitle={content[0]} content={content[1]} />
                </div>
                </section>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-our-associates-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateOurAssociatesSection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-our-associates-section"
                  value="Add Our Associates Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addOurAssociatesSections}
                />}
    </div>
  )
}

export default OurAssociatesSection