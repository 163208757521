import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import ActivitySectionHeading from '../../../../components/ActivityComponents/SectionHeading';
import img12 from "../../../../images/img12.jpg";
import img13 from "../../../../images/img13.jpg";
import img14 from "../../../../images/img14.jpg";
import img15 from "../../../../images/img15.jpg";
import { api_url } from '../../../../constants/base_url';
import Loading from '../../../../components/shared/Loading/Loading';

const GallerySection = () => {
    const {lang,blog, addSection, updateSection,isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")
    const urlToFile = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split("/").pop();
      return new File([blob], filename, { type: blob.type });
    };

    useEffect(() => {
        if(blog?.sub_card_7){
          setContent(blog?.sub_card_7?.content?.split("-.-"))
          setTitle(blog?.sub_card_7?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])
      const updateGallerySection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 7,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }

      const changeGalleryImage = async (i,img) => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("index", 7);
        formData.append("_method", "PUT");
        formData.append(`images[${i}]`, img);
        await updateSection(formData)
      }
      const addGallerySections = async () => {
        const formData = new FormData();
        formData.append('blogLanguageId', lang.id);
        formData.append('title', "Our Tour Gallery");
        await formData.append(`images[0]`, await urlToFile(img12));
        await formData.append(`images[1]`, await urlToFile(img13));
        await formData.append(`images[2]`, await urlToFile(img14));
        await formData.append(`images[3]`, await urlToFile(img15));
        formData.append('content', [
          "BEST TRAVELER'S SHARED PHOTOS",
          "Enjoy personalized service with our dedicated team, ensuring your travel experience is seamless and memorable. Our professional guides and 24/7 support are committed to providing exceptional, customized service for every traveler."
        ].join("-.-"));

        addSection(formData);
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_7?<>
            <section className="activity-section">
                <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-8">
                         <ActivitySectionHeading alignment="left" allContent={content} setTitle={setTitle} setContent={setContent} contentEditable title={title} subtitle={content[0]} content={content[1]} />
                        
                         <input
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          id={`gallery-fileInput-0`}
                          onChange={async (e)=>{
                          const file = e.target.files[0];
                          if (file) {
                              // const image = await urlToFile(file);
                              changeGalleryImage(0,file); // Pass the file directly
                          }
                          }}
                        />
                        <figure class="gallery-img justify-content-center d-flex" style={{cursor:"pointer"}}
                            onClick={() => {
                              document.getElementById(`gallery-fileInput-0`).click()
                            }}>
                          <img src={`${api_url}${blog?.sub_card_7?.image?.[0]}`.replace("/api/", "/storage/")} alt="" width={600}/>
                        </figure>
                      </div>
                      <div class="col-lg-4">
                        <div class="row">
                          <div class="col-sm-6">
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id={`gallery-fileInput-1`}
                            onChange={async (e)=>{
                            const file = e.target.files[0];
                            if (file) {
                                // const image = await urlToFile(file);
                                changeGalleryImage(1,file); // Pass the file directly
                            }
                            }}
                          />
                            <figure class="gallery-img" style={{cursor:"pointer"}}
                            onClick={() => {
                              document.getElementById(`gallery-fileInput-1`).click()
                            }}>
                              <img src={`${api_url}${blog?.sub_card_7?.image?.[1]}`.replace("/api/", "/storage/")} alt="" />
                            </figure>
                          </div>
                          <div class="col-sm-6">
                          <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              id={`gallery-fileInput-2`}
                              onChange={async (e)=>{
                              const file = e.target.files[0];
                              if (file) {
                                  // const image = await urlToFile(file);
                                  changeGalleryImage(2,file); // Pass the file directly
                              }
                              }}
                            />
                            <figure class="gallery-img" style={{cursor:"pointer"}}
                            onClick={() => {
                              document.getElementById(`gallery-fileInput-2`).click()
                            }}>
                              <img src={`${api_url}${blog?.sub_card_7?.image?.[2]}`.replace("/api/", "/storage/")} alt="" />
                            </figure>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id={`gallery-fileInput-3`}
                            onChange={async (e)=>{
                            const file = e.target.files[0];
                            if (file) {
                                // const image = await urlToFile(file);
                                changeGalleryImage(3,file); // Pass the file directly
                            }
                            }}
                          />
                            <figure class="gallery-img" style={{cursor:"pointer"}}
                            onClick={() => {
                              document.getElementById(`gallery-fileInput-3`).click()
                            }}
                            >
                              <img src={`${api_url}${blog?.sub_card_7?.image?.[3]}`.replace("/api/", "/storage/")} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </section>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-gallery-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateGallerySection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-gallery-section"
                  value="Add Gallery Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addGallerySections}
                />}
    </div>
  )
}

export default GallerySection