import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import img24 from "../../../../images/img24.jpg";
import icon12 from "../../../../images/icon12.png";
import icon13 from "../../../../images/icon13.png";
import icon14 from "../../../../images/icon14.png";
import { Link } from 'react-router-dom';
import { api_url } from '../../../../constants/base_url';
import Loading from '../../../../components/shared/Loading/Loading';


const ContactSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")
    
    const urlToFile = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split("/").pop();
      return new File([blob], filename, { type: blob.type });
    };

    useEffect(() => {
        if(blog?.sub_card_10){
          setContent(blog?.sub_card_10?.content?.split("-.-"))
          setTitle(blog?.sub_card_10?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])
      const updateContactSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 10,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }

      const changeContactImage = async (img) => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("index", 10);
        formData.append("_method", "PUT");
        formData.append(`images[0]`, img);
        await updateSection(formData)
      }

      const addContactSections = async () => {
        const formData = new FormData();
        formData.append('blogLanguageId', lang.id);
        formData.append('title', "LET'S JOIN US FOR MORE UPDATE !!");
        await formData.append(`images[0]`, await urlToFile(img24));
        formData.append('content', [
          "info@albumtravel.com",
          "25B First Gate, Hadayek Al-Ahram, Second Floor, Flat 07, Giza, Egypt",
          "LEARN MORE",
          "+2 010 3397 3047",
          "+2 010 0842 7156",
        ].join("-.-"));

        await addSection(formData);
      }
  return (
    
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px", paddingTop:"60px"}}>
        {blog?.sub_card_10?<>
             <section class="contact-section">
            <div class="container">
                <div class="row">
                <div class="col-lg-4">
                <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`contact-fileInput`}
                      onChange={async (e)=>{
                      const file = e.target.files[0];
                      if (file) {
                          // const image = await urlToFile(file);
                          changeContactImage(file); // Pass the file directly
                      }
                      }}
                  />
                        <div
                        class="contact-img"
                        style={{ cursor:"pointer",backgroundImage: `url(${api_url}${blog?.sub_card_10?.image?.[0]})`.replace("/api/", "/storage/") }}
                        onClick={() => {
                          document.getElementById(`contact-fileInput`).click();
                        }}
                        ></div>
              </div>
              <div class="col-lg-8">
                <div class="contact-details-wrap">
                  <div class="row">
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon12} alt="" />
                        </div>
                        <ul>
                          <li>
                            <input type="text" value={content[0]} onChange={(e) => {
                                const newContent = [...content]
                                newContent[0] = e.target.value
                                setContent(newContent)
                            }} style={{
                                border:"none",
                                outline:"none",
                                backgroundColor:"transparent",
                                color:"white",
                                fontWeight:"700"
                            }}/>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon13} alt="" />
                        </div>
                        <ul>
                          {content.slice(3).map((item, index) => (<li key={index}>
                            <input type="text" value={item} onChange={(e) => {
                                const newContent = [...content]
                                newContent[3 + index] = e.target.value
                                setContent(newContent)
                            }} style={{
                                border:"none",
                                outline:"none",
                                backgroundColor:"transparent",
                                color:"white",
                                fontWeight:"700"
                            }}/>
                          </li>))}
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon14} alt="" />
                        </div>
                        <ul>
                          <li>
                          <textarea type="text" value={content[1]} onChange={(e) => {
                                const newContent = [...content]
                                newContent[1] = e.target.value
                                setContent(newContent)
                            }} style={{
                                border:"none",
                                outline:"none",
                                backgroundColor:"transparent",
                                color:"white",
                                fontWeight:"700"
                            }}/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-btn-wrap">
                <input type="text" value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }} style={{
                                border:"none",
                                outline:"none",
                                backgroundColor:"transparent",
                                color:"white",
                                fontWeight:"900",
                                fontSize:"32px",
                                width:"100%",
                                textTransform:"uppercase"
                            }}/>
                            <input type="text" value={content[2]} onChange={(e) => {
                                const newContent = [...content]
                                newContent[2] = e.target.value
                                setContent(newContent)
                            }} style={{
                                border:"none",
                                outline:"none",
                                backgroundColor:"#D51C29",
                                color:"white",
                                fontWeight:"700",
                                fontSize:"18px",
                                textAlign:"center",
                                textTransform:"uppercase"
                            }}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-contact-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateContactSection}
              />}
        </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:
        <input
        type="submit"
        name="add-contact-section"
        value="Add Contact Section"
        style={{margin:"auto", display:"block",}}
        onClick={addContactSections}
      />}
    </div>
  )
}

export default ContactSection