import Loading from "../shared/Loading/Loading";

export default function SliderItem({ isUpdatingCarousel, backgroundImage,index, urlToFile,title, contentEditable, onChange, updateCarousel, changeCarouselImage }) {
  return (
    <div>
      
      <div className="home-banner-items">
        <div
          className="banner-inner-wrap"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <div className="banner-content-wrap">
          <div className="container-fluid">
            <div className="banner-content text-center py-5">
              {contentEditable?<input type="text" className="banner-input" value={title} style={{backgroundColor:"transparent", border:"none", outline:"none", height:"fit-content", width:"100%", color:"white", textAlign:"center", fontSize:"3vw"}} onChange={onChange}/>:<h2 className="banner-title">{title}</h2>}
              {/* Home search field html start */}
            {contentEditable && (
              <div style={{display:"flex", alignItems:"center", gap:"20px", justifyContent:"center"}}>
               { isUpdatingCarousel?<Loading color={"#0791BE"}/>:<>
                <input
                  type="submit"
                  name="update-carousel"
                  value="Save"
                  onClick={updateCarousel}
                />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`fileInput-${index}`}
                  onChange={async (e)=>{
                    const file = e.target.files[0];
                    if (file) {
                      // const image = await urlToFile(file);
                      changeCarouselImage(index, file); // Pass the file directly
                    }
                  }}
                />
                <input
                  type="submit"
                  name="travel-search"
                  value="Change Image"
                  onClick={() => {
                    document.getElementById(`fileInput-${index}`).click()
                  }}
                />
                </>
                  }
              </div>
            )}
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    </div>
  );
}
