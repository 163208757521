import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import ActivitySectionHeading from '../../../../components/ActivityComponents/SectionHeading';
import Loading from '../../../../components/shared/Loading/Loading';

const TravelOfferSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")

    useEffect(() => {
        if(blog?.sub_card_6){
          setContent(blog?.sub_card_6?.content?.split("-.-"))
          setTitle(blog?.sub_card_6?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])
      const updateTravelOfferSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 6,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }
      const addTravelOfferSections = () => {
        addSection({
          blogLanguageId: lang.id,
          title: "Travel Offer & Discount",
          content: ["SPECIAL TRAVEL OFFER",
            "Elevate your travel experience with our curated collection of special travel offers. From luxury escapes to hidden gems, our upcoming selection is designed to provide you with unparalleled access to unique destinations and unforgettable adventures, creating memories that will last a lifetime.",
          ].join("-.-"),
        })
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_6?<>
            <section className="activity-section">
                <div className="container">
                    <ActivitySectionHeading allContent={content} setTitle={setTitle} setContent={setContent} contentEditable title={title} subtitle={content[0]} content={content[1]} />
                </div>
                </section>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-travel-offer-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateTravelOfferSection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-travel-offer-section"
                  value="Add Travel Offer Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addTravelOfferSections}
                />}
    </div>
  )
}

export default TravelOfferSection