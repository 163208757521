import ActivityCard from './activityCard'

import icon6 from "../../images/icon6.png";
import icon10 from "../../images/icon10.png";
import icon9 from "../../images/icon9.png";
import icon8 from "../../images/icon8.png";
import icon7 from "../../images/icon7.png";
import icon11 from "../../images/icon11.png";



const ActivityRow = ({activities}) => {
    const extractFirstNumber = (str) => {
        const match = str?.match(/\d+/);
        return match ? match[0] : null;
      };
    return (
        <>
            <div class="activity-inner row">
                <ActivityCard imgSrc={icon6} title={activities?.[0]||"Adventure"} destinationNum={extractFirstNumber(activities?.[1])||15}/>
                <ActivityCard imgSrc={icon10} title={activities?.[2]||"Trekking"} destinationNum={extractFirstNumber(activities?.[3])||12}/>
                <ActivityCard imgSrc={icon9} title={activities?.[4]||"Camp Fire"} destinationNum={extractFirstNumber(activities?.[5])||7}/>
                <ActivityCard imgSrc={icon8} title={activities?.[6] || "Off Road"} destinationNum={extractFirstNumber(activities?.[7])||15}/>
                <ActivityCard imgSrc={icon7} title={activities?.[8] || "Camping"} destinationNum={extractFirstNumber(activities?.[9])||13}/>
                <ActivityCard imgSrc={icon11} title={activities?.[10] || "Exploring"} destinationNum={extractFirstNumber(activities?.[11])||25}/>
            </div>
        </>
    )
}

export default ActivityRow