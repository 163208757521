import React, { useEffect, useRef, useState } from 'react'
import SectionHeading from '../../../../components/sectionheading';
import CustomButton from '../../../../components/shared/customButton';
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import Loading from '../../../../components/shared/Loading/Loading';

const ToursSection = () => {
  const {lang,blog, addSection,isAddSectionLoading, updateSection, isUpdateSectionLoading} = useHomeBlog()
  const [content, setContent] = useState("")
  const [title, setTitle] = useState("")
  const [buttonText, setButtonText] = useState("")
  useEffect(() => {
    if(blog?.sub_card_2){
      setContent(blog?.sub_card_2?.content?.split("-.-")[0])
      setTitle(blog?.sub_card_2?.title)
      setButtonText(blog?.sub_card_2?.content?.split("-.-")[2])
    }else{
      setContent("")
      setTitle("")
      setButtonText("")
    }
  },[blog, lang])

  const updateTourSection = () => {
    if(blog){
      updateSection({
        blogLanguageId: lang.id,
        index: 2,
        content: `${content}-.-${blog?.sub_card_2?.content?.split("-.-")[1]}-.-${buttonText}`,
        title: title,
        _method: "PUT"
      })
    }
  }

  const addToursSections = () => {
    addSection({
      blogLanguageId: lang.id,
      title: "Explore Great PLaces",
      content: ["Your Recently Viewed and Recommended Tours","Book now and save up to 15% with our Last Minute Deals","View All Packages"].join("-.-"),
    })
  }
  return (
    <div style={{width:"100%",position:"relative" ,outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
          {blog?.sub_card_2?<>
            <SectionHeading
              title={title}
              headingTextAlignment="text-center"
              containerTextsStyle="col-lg-8 offset-lg-2 mt-5"
              contentEditable
              key={blog._id}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            >
              <input key={blog._id} style={{width:"100%", textAlign:"center", border:"none", outline:"none", color:"#101F46", fontSize:"18px", fontWeight:"700"}} type="text" value={content} onChange={(e) => setContent(e.target.value)} />
            </SectionHeading>
            <CustomButton><input key={blog._id} style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "white",
              backgroundColor:"transparent",
              textAlign:"center",
              padding:"0",
              border:"none",
              outline:"none",
            }} type='text' value={buttonText} onChange={(e) => {
              setButtonText(e.target.value)
            }}/></CustomButton>
            {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
              type="submit"
              name="update-tour-section"
              value="Save"
              style={{margin:"auto"}}
              onClick={updateTourSection}
            />}
          </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-tour-section"
                  value="Add Tours Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addToursSections}
                />}
      </div>
  )
}

export default ToursSection