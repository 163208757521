import img23 from "../images/img23.jpg";
import Slider from "react-slick";
import img20 from "../images/img20.jpg";
import img21 from "../images/img21.jpg";
import img22 from "../images/img22.jpg";
import { useHomeBlog } from "../context/HomeBlogContext";
import { api_url } from "../constants/base_url";

const TestimonialItem = ({ imgSrc, quote, name, company }) => (
  <div className="testimonial-item text-center">
    <figure className="testimonial-img">
      <img src={imgSrc} alt="" />
    </figure>
    <div className="testimonial-content">
      <p>{quote}</p>
      <cite>
        {name}
        <span className="company">{company}</span>
      </cite>
    </div>
  </div>
);

const TestimonialSection = () => {
  const {blog, isBlogLoading} = useHomeBlog()
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div
      className="testimonial-section"
      style={{ backgroundImage: `url(${img23})` }}
    >
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-lg-10 offset-lg-1">
            <Slider
              className="testimonial-inner testimonial-slider"
              {...settings}
            >
              {blog?.sub_card_9?.content?.split("-.-")?.map((item, index) => <TestimonialItem key={index} imgSrc={`${api_url}${blog?.sub_card_9?.image?.[index]}`.replace("/api/","/storage/")} quote={item.split("@.@")?.[0]} name={item.split("@.@")?.[1]} company={item.split("@.@")?.[2]} />)}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
