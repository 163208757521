import img16 from "../../../../images/img16.jpg"
import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import { api_url } from "../../../../constants/base_url";
import Loading from "../../../../components/shared/Loading/Loading";

const SubscribeSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")

    const urlToFile = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split("/").pop();
      return new File([blob], filename, { type: blob.type });
    };
    useEffect(() => {
        if(blog?.sub_card_8){
          setContent(blog?.sub_card_8?.content?.split("-.-"))
          setTitle(blog?.sub_card_8?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])

      const changeBackgroundImage = async (img) => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("index", 8);
        formData.append("_method", "PUT");
        formData.append(`images[0]`, img);
        await updateSection(formData)
      }
      const updateSubscribeSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 8,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }
      const addSubscribeSections = async () => {

        const formData = new FormData();
        formData.append('blogLanguageId', lang.id);
        formData.append('title', "Holiday Package Offer");
        await formData.append('images[0]', await urlToFile(img16));
        formData.append('content', [
            "HOLIDAY SPECIAL 25% OFF !",
            "Sign up now to recieve hot special offers and information about the best tour packages, updates and discounts !!",
            "SIGN UP NOW!",
            "Enjoy a festive 25% discount with our Holiday Special! Sign up today for exclusive offers on top tour packages and stay updated with the latest discounts and updates."
        ].join("-.-"));

        addSection(formData);
      }
    return (
        <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
            {blog?.sub_card_8?<>
                <section className="subscribe-section" style={{ backgroundImage: `url(${api_url}${blog?.sub_card_8?.image?.[0]})`.replace("/api/", "/storage/") }}>
                <div className="container" style={{zIndex:2000, position:"relative"}}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="section-heading section-heading-white">
                                <input type="text" value={title} onChange={(e) => {
                                    setTitle(e.target.value)
                                    }} style={{color: "white",
                                        backgroundColor: "transparent",
                                        
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "1.2",
                                        marginBottom: "22px",
                                        textTransform: "uppercase",
                                        display: "inline-block",
                                        width:"100%",
                                        border:"none",
                                        outline:"none",
                                        textAlign:"left"}} />
                                    <textarea style={{width:"100%", backgroundColor: "transparent",textAlign:"left", border:"none", outline:"none", color:"white", fontSize:"48px", fontWeight:"900"}} type="text" value={content[0]} onChange={(e) => {
                                        const newContent = [...content]
                                        newContent[0] = e.target.value
                                        setContent(newContent)
                                        }} />
                                    <textarea type="text" value={content[1]} onChange={(e) => {
                                    const newContent = [...content]
                                    newContent[1] = e.target.value
                                    setContent(newContent)
                                    }} style={{color:"white",
                                        backgroundColor: "transparent",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        lineHeight: "1.2",
                                        marginBottom: "22px",
                                        display: "inline-block",
                                        width:"100%",
                                        border:"none",
                                        outline:"none",
                                        textAlign:"left"}} />
                                    <div className="newsletter-form">
                                        <form>
                                            <input style={{display:"inline-block", width:"70%"}} type="email" disabled name="s" placeholder="Your Email Address" />
                                            <input type="text" name="signup" value={content[2]} onChange={(e) => {
                                            const newContent = [...content]
                                            newContent[2] = e.target.value
                                            setContent(newContent)
                                            }} 
                                            style={{
                                                textAlign:"center",
                                                backgroundColor: "#0791BE",
                                                color:"white",
                                                display:"inline-block",
                                                marginLeft:"10px",
                                                height:"60px"
                                            }}
                                            />
                                        </form>
                                    </div>
                                    <textarea type="text" value={content[3]} onChange={(e) => {
                                    const newContent = [...content]
                                    newContent[3] = e.target.value
                                    setContent(newContent)
                                    }} style={{color:"white",
                                        backgroundColor: "transparent",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        lineHeight: "1.2",
                                        marginBottom: "22px",
                                        display: "inline-block",
                                        width:"100%",
                                        border:"none",
                                        outline:"none",
                                        textAlign:"left"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<>
                <input
                  type="submit"
                  name="update-subscribe-section"
                  value="Save"
                  style={{margin:"auto"}}
                  onClick={updateSubscribeSection}
                />
                <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`subscribe-background-fileInput`}
                      onChange={async (e)=>{
                      const file = e.target.files[0];
                      if (file) {
                          // const image = await urlToFile(file);
                          changeBackgroundImage(file); // Pass the file directly
                      }
                      }}
                  />
                  <input
                  type="submit"
                  name="change-subscribe-background"
                  value="Change Background Image"
                  style={{margin:"auto", marginLeft:"20px"}}
                  onClick={() => {
                      document.getElementById(`subscribe-background-fileInput`).click()
                  }}
                />
                </>}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
            type="submit"
            name="add-subscribe-section"
            value="Add Subscribe Section"
            style={{margin:"auto", display:"block",}}
            onClick={addSubscribeSections}
        />}
        </div>
        
    )
}

export default SubscribeSection