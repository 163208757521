import React from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import Slider from "react-slick";
import img21 from "../../../../images/img21.jpg";
import img23 from "../../../../images/img23.jpg";
import Loading from '../../../../components/shared/Loading/Loading';

const TestimonialItem = ({ imgSrc, quote, name, company, index, onChange, updateTestimonials, changeTestimonialImage, urlToFile, isUpdateSectionLoading }) => (
    <div className="testimonial-item text-center">
      <figure className="testimonial-img">
        <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id={`testimonial-fileInput-${index}`}
            onChange={async (e)=>{
            const file = e.target.files[0];
            if (file) {
                // const image = await urlToFile(file);
                changeTestimonialImage(index, file); // Pass the file directly
            }
            }}
        />
        <img src={imgSrc} alt="" style={{cursor:"pointer"}} onClick={() => {
            document.getElementById(`testimonial-fileInput-${index}`).click()
        }} />
      </figure>
      <div className="testimonial-content">
        <textarea type="text" value={quote} style={{
            width:"100%",
            textAlign:"center",
            outline:"none",
            border:"none",
            backgroundColor:"transparent",
            color:"#222",
            fontSize:"1.2rem",
            fontStyle:"italic"
        }}
        onChange={(e) => {
            onChange(e.target.value, 0)
        }}
        />
        <cite>
          <input type="text" value={name}
            style={{
                width:"100%",
                textAlign:"center",
                outline:"none",
                border:"none",
                backgroundColor:"transparent",
                color:"#0791BE",
                fontSize:"1.4rem",
                fontStyle:"italic",
                fontWeight:"700"
            }}
            onChange={(e) => {
                onChange(e.target.value, 1)
            }}
          />
          <input type='text' value={company} 
            style={{
                width:"100%",
                textAlign:"center",
                outline:"none",
                border:"none",
                backgroundColor:"transparent",
                color:"#777",
                fontSize:"1rem",
                fontStyle:"italic"
            }}
            onChange={(e) => {
                onChange(e.target.value, 2)
            }}
          />
          {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
            type="submit"
            name="update-subscribe-section"
            value="Save"
            style={{margin:"auto"}}
            onClick={updateTestimonials}
          />}
        </cite>
      </div>
    </div>
  );

const TestimonialSection = ({testimonialItems, setTestimonialItems}) => {
    const {lang, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    var settings = {
        dots: true,
        infinite: testimonialItems?.length > 1 ? true :false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
      };
      const urlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.split("/").pop();
        return new File([blob], filename, { type: blob.type });
      };
      const updateTestimonials = () => {
        updateSection({
          blogLanguageId: lang.id,
          index: 9,
          content: testimonialItems?.map(item => item.content).join("-.-"),
          _method: "PUT"
        })
      }
      const changeTestimonialImage = async (i, img) => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("index", 9);
        formData.append("_method", "PUT");
        formData.append(`images[${i}]`, img);
        await updateSection(formData)
      }
      const addTestimonial = async () => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("title", "Testimonial");
        formData.append(`images[0]`, await urlToFile(img21));
        formData.append(`images[1]`, await urlToFile(img21));
        formData.append(`content`, "Placeholder sentence 1@.@User1@.@Travel Agent-.-Placeholder sentence 2@.@User2@.@Travel Agent");
        addSection(formData);
      };
      const addTestimonialItem = async () => {
        const formData = new FormData();
        formData.append("blogLanguageId", lang.id);
        formData.append("index", 9);
        formData.append("_method", "PUT");
        formData.append(`images[${testimonialItems?.length}]`, await urlToFile(img21));
        formData.append("content", [...testimonialItems, {content:`Placeholder sentence ${testimonialItems?.length + 1}@.@User${testimonialItems?.length + 1}@.@Travel Agent`}].map(item => item.content).join("-.-"));
        await updateSection(formData)
      }

      
      /*
      urlToFile={urlToFile} index={index} contentEditable onChange={(e) => {
          let newCarouselItems = [...carouselItems]
          newCarouselItems[index].content = e.target.value
          setCarouselItems(newCarouselItems)
        }} updateCarousel={updateCarousel} changeCarouselImage={changeCarouselImage} backgroundImage={item.image} title={item.content}
       */
  return (
    <div style={{width:"100%",position:"relative", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px 0"}}>
        {testimonialItems && <input
          type="submit"
          name="add-testimonial-item"
          value="Add Testimonial Item"
          onClick={addTestimonialItem}
          style={{
            marginBottom:"40px",
            position:"absolute",
            top:"20px",
            left:"20px",
            zIndex:"100",
          }}/>
        }
        {testimonialItems?<div
      className="testimonial-section"
      style={{ backgroundImage: `url(${img23})` }}
    >
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-lg-10 offset-lg-1">
            <Slider
              className="testimonial-inner testimonial-slider"
              {...settings}
            >

                {testimonialItems?.map((item, index) => (<TestimonialItem isUpdateSectionLoading={isUpdateSectionLoading} onChange={(data, idx) => {
                    const newContent = testimonialItems[index].content.split("@.@")
                    newContent[idx] = data
                    let newTestimonialItems = [...testimonialItems]
                    newTestimonialItems[index].content = newContent.join("@.@")
                    setTestimonialItems(newTestimonialItems)
                }} index={index} imgSrc={item?.image} quote={item?.content?.split("@.@")?.[0]} name={item?.content?.split("@.@")?.[1]} company={item?.content?.split("@.@")?.[2]} key={index} updateTestimonials={updateTestimonials} changeTestimonialImage={changeTestimonialImage} urlToFile={urlToFile}/>))}
            </Slider>
                </div>
                </div>
                </div>
                </div>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="add-testimonial-section"
                value="Add a Testimonial"
                style={{margin:"auto", display:"block",}}
                onClick={addTestimonial}
              />}
    </div>
  )
}

export default TestimonialSection