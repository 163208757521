import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import ActivitySectionHeading from '../../../../components/ActivityComponents/SectionHeading';
import Loading from '../../../../components/shared/Loading/Loading';
import Footer from '../../../../components/footer';

const FooterSection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState([])

    useEffect(() => {
        if(blog?.sub_card_13){
          setContent(blog?.sub_card_13?.content?.split("-.-"))
          setTitle(blog?.sub_card_13?.title?.split("-.-"))
        }else{
          setContent([])
          setTitle([])
        }
      },[blog, lang])
      const updateFooterSection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 13,
            content: content.join("-.-"),
            title: title.join("-.-"),
            _method: "PUT"
          })
        }
      }
      const addFooterSections = () => {
        addSection({
          blogLanguageId: lang.id,
          title: [
            "ABOUT TRAVEL",
            "CONTACT INFORMATION",
            "LATEST POST",
            "CONTACT US"
          ].join("-.-"),
          content: ["We are committed to curate and flawlessly execute travel experiences that allow travelers to explore the world with ease and create memories that last a lifetime.",
            "PARTNER'S AND CLIENTS .",
            "+201033977047",
            "+201008427156",
            "info@albumtravel.com",
            "25B First Gate, Hadayek Al-Ahram, Second Floor, Flat 07, Giza, Egypt",
            "Post 1",
            "Post 2",
            "LET'S JOIN US FOR MORE UPDATE !!",
            "Contact Us",
            "Privacy Policy",
            "Terms & Conditions",
            "Copyright © 2024 Album Travel. All rights reserved",
            "Developed by 7P",
          ].join("-.-"),
        })
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_13?<>
                <Footer contentEditable content={content} setContent={setContent} title={title} setTitle={setTitle} />
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-footer-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateFooterSection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-footer-section"
                  value="Add footer Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addFooterSections}
                />}
    </div>
  )
}

export default FooterSection