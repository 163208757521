import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import ActivityItem from '../../../../components/ActivityComponents/ActivityItem';
import img1 from "../../../../images/icon6.png";
import img2 from "../../../../images/icon10.png";
import img3 from "../../../../images/icon9.png";
import img4 from "../../../../images/icon8.png";
import img5 from "../../../../images/icon7.png";
import img6 from "../../../../images/icon11.png";
import ActivitySectionHeading from '../../../../components/ActivityComponents/SectionHeading';
import Loading from '../../../../components/shared/Loading/Loading';

const ActivitySection = () => {
    const {lang,blog, addSection, updateSection, isAddSectionLoading, isUpdateSectionLoading} = useHomeBlog()
    const [content, setContent] = useState([])
    const [title, setTitle] = useState("")
    const imgs = [img1, img2, img3, img4, img5, img6]
    useEffect(() => {
        if(blog?.sub_card_5){
          setContent(blog?.sub_card_5?.content?.split("-.-"))
          setTitle(blog?.sub_card_5?.title)
        }else{
          setContent([])
          setTitle("")
        }
      },[blog, lang])

      const updateActivitySection = () => {
        if(blog){
          updateSection({
            blogLanguageId: lang.id,
            index: 5,
            content: content.join("-.-"),
            title: title,
            _method: "PUT"
          })
        }
      }
      const addActivitySections = () => {
        addSection({
          blogLanguageId: lang.id,
          title: "Travel By Activity",
          content: ["ADVENTURE & ACTIVITY",
            "Step into a world of excitement and discovery as you embark on thrilling journeys and engage in immersive activities. From awe-inspiring natural wonders to captivating cultural encounters, our upcoming travel offers will satisfy your thirst for adventure and create unforgettable moments.",
            "Adventure",
            "15 Destination",
            "Trekking",
            "12 Destination",
            "Camp Fire",
            "7 Destination",
            "Off Road",
            "15 Destination",
            "Camping",
            "13 Destination",
            "Exploring",
            "25 Destination",
          ].join("-.-"),
        })
      }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
        {blog?.sub_card_5?<>
            <section className="activity-section">
                <div className="container">
                    <ActivitySectionHeading allContent={content} setTitle={setTitle} setContent={setContent} contentEditable title={title} subtitle={content[0]} content={content[1]} />
                    <div className="activity-inner row">
                    {Array(6).fill(0).map((_, i) => (
                        <div className="col-lg-2 col-md-4 col-sm-6" key={i}>
                        <ActivityItem index = {i*2 + 2} contentEditable setContent={setContent} content={content} title={content[i*2 + 2]} destinations={content[i*2 + 3]} icon={imgs[i]}/>
                        </div>
                    ))}
                    </div>
                </div>
                </section>
              {isUpdateSectionLoading?<Loading color={"#0791BE"}/>:<input
                type="submit"
                name="update-activity-section"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateActivitySection}
              />}
            </>:isAddSectionLoading?<Loading color={"#0791BE"}/>:<input
                  type="submit"
                  name="add-activity-section"
                  value="Add Activity Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addActivitySections}
                />}
    </div>
  )
}

export default ActivitySection