import { useState } from "react";
import "../../dashboardIndex.css";
import home from "./dashboard.module.css";

import Table from "../../components/customTable/table";
import DashboardStats from "./dashboardStats/DashboardStats";
import DashboardLanguages from "./dashboardLanguages/DashboardLanguages";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "axios";
import { api_url } from "../../../constants/base_url";
import { useQuery } from "@tanstack/react-query";

const DashboardMain = () => {
  const { data: inquiries } = useQuery({
    queryKey: ["allInquiriesHome"],
    queryFn: () =>
      axios.get(`${api_url}inquiries`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });
  const { data: reservations } = useQuery({
    queryKey: ["allReservationsHome"],
    queryFn: () =>
      axios.get(`${api_url}reservation/adminShow`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  return (
    <main className={home.mainContainer}>
      {/* <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 2, 500: 2, 700: 2, 900: 2, 1300: 3 }}
        >
        <Masonry gutter="30px">
        
        </Masonry>
        </ResponsiveMasonry> */}
        <section className={home.recentBooking}>
          <div className={home.heading}>
            <h3>Recent Reservations</h3>
          </div>
          <Table className={home.recentBookingTable}>
            <Table.HeadController>
              <th>User</th>
              <th>Tour</th>
              <th>From</th>
              <th>Total Price</th>
            </Table.HeadController>
            <Table.BodyController>
              {reservations?.data?.data?.data?.length > 0 ? (
                reservations.data.data.data.map((reservation) => (
                  <tr key={reservation.id}>
                    <td>
                      {reservation?.user_name.length > 15
                        ? `${reservation?.user_name.slice(0, 15)}...`
                        : reservation?.user_name}
                    </td>
                    <td>
                      {reservation.tour_name.length > 15
                        ? `${reservation.tour_name.slice(0, 15)}...`
                        : reservation.tour_name}
                    </td>
                    <td>
                      {reservation.reservation_date.length > 15
                        ? `${reservation.reservation_date.slice(0, 15)}...`
                        : reservation.reservation_date}
                    </td>
                    <td>
                      {reservation.total_price.length > 15
                        ? `${reservation.total_price.slice(0, 15)}...`
                        : reservation.total_price}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data to display</td>
                </tr>
              )}
            </Table.BodyController>
          </Table>
        </section>
          <section className={home.recentBooking}>
            <div className={home.heading}>
              <h3>Recent Inquiries</h3>
            </div>
            <Table className={home.recentBookingTable}>
              <Table.HeadController>
                <th>Email</th>
                <th>Phone</th>
                <th>Nationality</th>
                <th>Destination</th>
                <th>Status</th>
              </Table.HeadController>
              <Table.BodyController>
                {inquiries?.data?.data?.data?.length > 0 ? (
                  inquiries.data.data.data.map((inquiry) => (
                    <tr key={inquiry.id}>
                      <td>
                        {inquiry?.email.length > 15
                          ? `${inquiry?.email.slice(0, 15)}...`
                          : inquiry?.email}
                      </td>
                      <td>
                        {inquiry.contact_number.length > 15
                          ? `${inquiry.contact_number.slice(0, 15)}...`
                          : inquiry.contact_number}
                      </td>
                      <td>
                        {inquiry.nationality.length > 15
                          ? `${inquiry.nationality.slice(0, 15)}...`
                          : inquiry.nationality}
                      </td>
                      <td>
                        {inquiry.destination_name.length > 15
                          ? `${inquiry.destination_name.slice(0, 15)}...`
                          : inquiry.destination_name}
                      </td>
                      <td>
                        {inquiry.status.length > 15
                          ? `${inquiry.status.slice(0, 15)}...`
                          : inquiry.status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No data to display</td>
                  </tr>
                )}
              </Table.BodyController>
            </Table>
          </section>
          <DashboardStats />
          <DashboardLanguages />
    </main>
  );
};

export default DashboardMain;
