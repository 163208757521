import { useState } from "react";
import img8 from "../images/img8.jpg";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/icon4.png";
import icon5 from "../images/icon5.png";
import video from "../images/Sequence.mp4";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useHomeBlog } from "../context/HomeBlogContext";
import { api_url } from "../constants/base_url";

const CounterItem = ({ iconSrc, counter, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="counter-item" ref={ref}>
      <div className="counter-icon">
        <img src={iconSrc} alt="" />
      </div>
      <div className="counter-content">
        <span className="counter-no">
          <span className="counter">
            {inView ? <CountUp end={counter} duration={2} /> : 0}
          </span>
          K+
        </span>
        <span className="counter-text">{text}</span>
      </div>
    </div>
  );
};

// SupportArea component
const SupportArea = ({ iconSrc, phone, description, call }) => (
  <div className="support-area">
    <div className="support-icon">
      <img src={iconSrc} alt="" />
    </div>
    <div className="support-content">
      <h4>{description || "Our 24/7 Emergency Phone Services"}</h4>
      <h3>
        <Link to={`tel:${phone || "+201033973047"}`}>{call||"Call"}: {phone}</Link>
      </h3>
    </div>
  </div>
);

// CallbackInner component
const CallbackInner = ({title, content}) => {
  const allContent = content?.split("-.-")
  const extractFirstNumber = (str) => {
    const match = str?.match(/\d+/);
    return match ? match[0] : null;
  };
  return (
    <div className="callback-inner">
      <div className="section-heading section-heading-white">
        <h5 className="dash-style">{title || "CALLBACK FOR MORE"}</h5>
        <h2>{allContent?.[0]||"GO TRAVEL. DISCOVER. REMEMBER US!!"}</h2>
        <p>
          {allContent?.[1] || "We are committed to curate and flawlessly execute travel experiences that allow travelers to explore the world with ease and create memories that last a lifetime"}
        </p>
      </div>
      <div className="callback-counter-wrap">
        <CounterItem iconSrc={icon1} counter={extractFirstNumber(allContent?.[2])||500} text={allContent?.[3]||"Satisfied Clients"} />
        <CounterItem iconSrc={icon2} counter={extractFirstNumber(allContent?.[4])||250} text={allContent?.[5]||"Satisfied Clients"} />
        <CounterItem iconSrc={icon3} counter={extractFirstNumber(allContent?.[6])||15} text={allContent?.[7]||"Satisfied Clients"} />
        <CounterItem iconSrc={icon4} counter={extractFirstNumber(allContent?.[8])||10} text={allContent?.[9]||"Satisfied Clients"} />
      </div>
      <SupportArea description={allContent?.[10]} call={allContent?.[11]} iconSrc={icon5} phone={allContent?.[12]||"+201033973047"} />
    </div>
  );
}

// CallbackImg component
const CallbackImg = ({ backgroundImage }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = (id) => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div
        className="callback-img"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="video-button">
          <a
            id="video-container"
            data-video-id="IUN664s7N-c"
            onClick={() => handleShow("IUN664s7N-c")}
          >
            <i className="fas fa-play"></i>
          </a>
        </div>
        <Modal show={showModal} handleClose={handleClose} videoSrc={video} />
      </div>
    </>
  );
};

// Main CallbackSection component
const CallbackSection = () => {
  const {blog, isBlogLoading} = useHomeBlog();
  return (
    <section className="callback-section">
      <div className="container-fluid">
        <div className="row no-gutters align-items-center">
          <div className="col-lg-5">
            <CallbackImg backgroundImage={blog?.sub_card_4?.image[0]?`${api_url}${blog?.sub_card_4?.image[0]}`.replace("/api/","/storage/") :img8} />
          </div>
          <div className="col-lg-7">
            <CallbackInner title={blog?.sub_card_4?.title} content={blog?.sub_card_4?.content}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallbackSection;

const Modal = ({ show, handleClose, videoSrc }) => {
  const showHideClassName = show ? " display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button onClick={handleClose} className="close-button p-3">
          X
        </button>
        <video width="100%" height="100%" controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
    </div>
  );
};
