import DestinationCard from './destinationCard'

import CustomButton from '../shared/customButton';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../constants/base_url';

const DestinationRow = ({btnText}) => {
    const { data } = useQuery({
        queryKey: ['destinations'],
        queryFn: () =>
          axios.get(`${api_url}destinations`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }),
        onSuccess: (response) => {
          console.log("Data fetched successfully:", response.data);
        },
        onError: (err) => {
          console.error("Error fetching data:", err);
        }
      });
    return (
        <>
            <div class="destination-inner destination-three-column">
                <div class="row">
                {data?.data?.data?.map((destination) => (
                    <DestinationCard id={destination?.id} title={destination?.name} imgSrc={`${api_url}${destination.image}`.replace("/api/", "/storage/")}/>
                ))}
                </div>
                <CustomButton>{btnText || "MORE DESTINATION"}</CustomButton>
            </div>
        </>
    )
}

export default DestinationRow